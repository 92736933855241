import requestService from "@/services/requests";
import { Browser } from "@capacitor/browser";

async function fetchPDFData(id: string) {
  try {
    const response = await requestService.getWithParams<any>(`milage-info`, {
      id: id,
    });

    const pdfSource = response.data.fetchURL + "#toolbar=0&zoom=page-width";
    const downloadEndpoint = response.data.downloadEndpoint;
    const fileName = response.data.fileName;
    //window.open(pdfSource, "_blank");
    await Browser.open({ url: pdfSource });
    console.log("PDF loaded", pdfSource);
  } catch (error) {
    console.error("Error fetching PDF data:", error);
  }
}

export default fetchPDFData;
