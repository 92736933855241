import {
  getMediaFile,
  arrayBufferToBase64,
  getRemoteMediaFile,
  fetchLocalIFile,
} from "@/helpers/api";
import {
  readFile,
  writeFile,
  blobToBase64,
  getFile,
} from "@/helpers/filesReadWrite";
import {  IFile,
  ITask0, } from "@/modules/j.createWorksheets/stores/createWksStore";
import requestService from "@/services/requests";
import {
  resolveMimeType,
  MimeType,
} from "@/globals/helpers/media/mimeTypeUtils";
import { useIFileResolver } from "@/modules/a.worksheets/helpers/useIFileResolver";

interface PreparedTasksData {
  tasks: string;
  [key: `file-${string}.${string}`]: Blob;
}

type stringifyOptions = {
  isDataLocale: boolean;
};

type IFileUri = { identifier: string; localUri: string };

async function stringify(
  data: any,
  options: stringifyOptions = { isDataLocale: false }
) {
  return JSON.stringify(await _stringify(data, options));
}

async function _stringify(data: any, options: stringifyOptions) {
  let objCopy: any = {};

  if (data instanceof File) {
    const file = data as File;
    const fileExtension = file.type.split("/")[1] || "unknown";
    const id = `file-${generateUniqueFileId()}.${fileExtension}`;
    await writeFile(id, file);
    objCopy = id;
  } else if (typeof data === "boolean") {
    objCopy = data;
  } else if (isObjOfTypeIFile(data)) {
    const file = data as IFile;
    file.isLocal = true;
    const uri = await writeFilesToFileSystem(file, options.isDataLocale);
    console.warn("written file to filesystem: ", file);
    console.warn("... with uri: ", uri);
    if (uri) {
      file.filepath = uri;
      objCopy = file;
    }
  } else {
    for (const key in data) {
      if (!Object.hasOwn(data, key)) {
        console.warn("has own exception!");
        continue;
      }
      try {
        if (Array.isArray(data[key])) {
          objCopy[key] = await Promise.all(
            (data[key] as Array<any>).map(
              async (item) => await _stringify(item, options)
            )
          );
        } else if (typeof data[key] === "object" && data[key] !== null) {
          objCopy[key] = await _stringify(data[key], options);
        } else {
          objCopy[key] = data[key];
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
  return objCopy;
}

async function inspect(
  data: any,
  hooks: {
    onFile?: (file: File) => Promise<void> | void;
    onIFile?: (file: IFile) => Promise<void> | void;
    onBoolean?: (bool: boolean) => Promise<void> | void;
    onString?: (str: string) => Promise<void> | void;
    onNumber?: (num: number) => Promise<void> | void;
  }
) {
  if (data instanceof File && hooks.onFile) {
    await hooks.onFile(data);
  } else if (typeof data === "boolean" && hooks.onBoolean) {
    await hooks.onBoolean(data);
  } else if (isObjOfTypeIFile(data) && hooks.onIFile) {
    await hooks.onIFile(data as IFile);
  } else if (typeof data === "string" && hooks.onString) {
    await hooks.onString(data);
  } else if (typeof data === "number" && hooks.onNumber) {
    await hooks.onNumber(data);
  } else {
    for (const key in data) {
      if (!Object.hasOwn(data, key)) {
        console.warn("has own exception!");
        continue;
      }
      try {
        if (Array.isArray(data[key])) {
          await Promise.all(
            (data[key] as Array<any>).map(
              async (item) => await inspect(item, hooks)
            )
          );
        } else if (typeof data[key] === "object" && data[key] !== null) {
          await inspect(data[key], hooks);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
}

async function parse(dataString: string) {
  const data = JSON.parse(dataString);
  const parsedData = await _parse(data);
  return parsedData;
}

async function _parse(data: any) {
  let objCopy: any = {};

  if (typeof data === "string" && isFileIdentifier(data)) {
    const result = await readFile(data);
    (objCopy as string) = result.uri;
  } else if (typeof data === "boolean") {
    (objCopy as boolean) = data;
  } else {
    for (const key in data) {
      if (!Object.hasOwn(data, key)) {
        console.warn("has own exception!");
        continue;
      }
      try {
        if (Array.isArray(data[key])) {
          objCopy[key] = await Promise.all(
            (data[key] as Array<any>).map(async (item) => await _parse(item))
          );
        } else if (typeof data[key] === "object" && data[key] !== null) {
          objCopy[key] = await _parse(data[key]);
        } else {
          objCopy[key] = data[key];
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
  return objCopy;
}

const isObjOfTypeIFile = (
  data: any,
  options: { skipEmpty?: boolean } = {}
): boolean => {
  if (typeof data !== "object" || data === null) return false;

  if (options.skipEmpty) {
    return (
      "filename" in data &&
      "filetype" in data &&
      "filepath" in data &&
      "isToUpdate" in data &&
      data.filename !== "" &&
      data.filetype !== "" &&
      data.filepath !== ""
    );
  }
  return (
    "filename" in data &&
    "filetype" in data &&
    "filepath" in data &&
    "isToUpdate" in data
  );
};

///! Lol, this is not writing the files to the filesystem...
//! TODO: Refactor this function!
async function writeFilesToFileSystem(
  data: IFile,
  isDataLocale: boolean
): Promise<string | undefined> {
  if (isDataLocale) {
    // ----------------- LOCAL DATA -----------------
    const localFile = await getFile(data.filename);

    let blobUrl = "";
    if (typeof localFile.data === "string") {
      const base64 = localFile.data.split(",")[1];
      const type = localFile.data.split(",")[0].split(";")[0].split(":")[1];
      const binary = atob(base64);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(array)], { type: type });
      blobUrl = URL.createObjectURL(blob);
    } else {
      blobUrl = URL.createObjectURL(localFile.data);
    }
    return blobUrl;
  } else {
    // ----------------- SERVER DATA -----------------
    const resp = await requestService.getServerMediaFile<ArrayBuffer>(
      data.filepath,
      data.filename,
      data.filetype
    );
    //const resp2 = await getRemoteMediaFile(data.filepath, data.filename, data.filetype);

    const contentType: string = resp.headers["content-type"];
    const mimetype =
      resolveMimeType(contentType) ?? resolveMimeType(data.filetype);
    if (mimetype === undefined) {
      console.error("mime type is undefined!");
      return;
    }

    const fileKey = "wks_files/" + data.filename;
    console.log("SAVING FILE UNDER KEY: ", fileKey);
    const base64 = await arrayBufferToBase64(
      resp.data,
      mimetype ?? contentType
    );
    const uri = await writeFile(fileKey, base64);
    return uri.uri;
  }
}


async function serializeTasks(data: ITask0[]): Promise<FormData> {
  const { cleanedObject, iFileUris } = await serializeIter(data);

  console.log("CLEANED OBJECT: ", cleanedObject);

  console.log("iFileUris: ", iFileUris);
  const formData = new FormData();
  formData.append("tasks", JSON.stringify(cleanedObject));
  for (let index = 0; index < iFileUris.length; index++) {
    const uri = iFileUris[index];
    const blob = await fetch(uri.localUri).then((r) => r.blob());
    console.log("fetched blob: ", blob);
    formData.append(uri.identifier, blob);
  }
  return formData;
}

async function serializeIter<T extends object>(
  data: T
): Promise<{
  cleanedObject: T;
  iFileUris: IFileUri[];
}> {
  let objCopy: any = {};
  const iFileUris: { identifier: string; localUri: string }[] = [];

  if (data instanceof File) {
    const file = data as File;
    const fileExtension = file.type.split("/")[1] || "unknown";
    const id = `file-${generateUniqueFileId()}.${fileExtension}`;

    const uri = URL.createObjectURL(file);
    console.log("URIIIIIIIIIIIIIIIIIII:")
    objCopy = id;
    iFileUris.push({ identifier: id, localUri: uri });
  } else if (typeof data === "boolean") {
    objCopy = data;
  } else if (isObjOfTypeIFile(data, { skipEmpty: true })) {
    const iFile = data as IFile;

    if (iFile.isToUpdate) {
      const id = getLocalIFileId(iFile);

      const uri = iFile.filepath;
      iFileUris.push({ identifier: id, localUri: uri });

      objCopy = {
        filename: iFile.filename,
        filetype: iFile.filetype,
        filepath: id,
        isToUpdate: iFile.isToUpdate,
      } as IFile;
    } else {
      /// Enable this to send the already present file to the server
      // const {promise: {resolveFile}} = useIFileResolver();
      // const file = await resolveFile(iFile, true);
      // if(file){
      //   const id = `file-${generateUniqueFileId()}.${iFile.filetype}`;
      //   iFileUris.push({ identifier: id, localUri: file.source });
      //   objCopy = {
      //     filename: iFile.filename,
      //     filetype: file.type,
      //     filepath: id,
      //     isToUpdate: iFile.isToUpdate,
      //   } as IFile;
      // }
      /// Uncomment to not send file to server
      objCopy = iFile;
    }
  } else {
    for (const key in data) {
      if (!Object.hasOwn(data, key)) {
        console.warn("has own exception!");
        continue;
      }
      try {
        if (Array.isArray(data[key])) {
          const dataArr = await Promise.all(
            (data[key] as Array<any>).map((item) => serializeIter(item))
          );
          objCopy[key] = dataArr.map((item) => item.cleanedObject);
          dataArr.forEach((item) => {
            item.iFileUris.forEach((value) => {
              iFileUris.push(value);
            });
          });
        } else if (typeof data[key] === "object" && data[key] !== null) {
          const item = await serializeIter(data[key]!);
          objCopy[key] = item.cleanedObject;
          item.iFileUris.forEach((value) => {
            iFileUris.push(value);
          });
        } else {
          objCopy[key] = data[key];
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
  return {
    cleanedObject: objCopy,
    iFileUris: iFileUris,
  };
}

/**
 * Generates a unique file identifier.
 * @returns {string} A unique file identifier.
 */
function generateUniqueFileId() {
  return "xxxx-xxxx-xxxx-xxxx".replace(/[x]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    return r.toString(16);
  });
}

function isFileIdentifier(str: string) {
  const pattern = /^file(-[0-9a-zA-Z]{4}){4}\.[a-zA-Z0-9]+$/;
  return pattern.test(str);
}

const getLocalIFileId = (file: IFile) => `${file.filepath}/${file.filename}`;

export {
  stringify,
  stringifyOptions,
  parse,
  serializeTasks,
  inspect,
  serializeIter,
  getLocalIFileId,
  IFileUri,
};
