import { IActivity } from "@/modules/a.worksheets/stores/worksheetDataStore";
import {
  ionicStorageClass,
  Stringified,
} from "@/persistentStorage/ionicStorageClass";
import { getDir, createFolder } from "@/helpers/imagesReadWrite";
import { serializeIter } from "@/persistentStorage/wks_storage/customStringify";

type UUID = string;
type FilePathUri = string;

// We map Activity UUID to a ActivitySpace. 
type TActivityStorage = Map<string, FilePathUri>;

class ActivityStorage extends ionicStorageClass<TActivityStorage> {
  private mountKey: string = "worksheet-creation-activity-db";
  private folderName: string = "wks_creation_activity_db_folder";
  private isInitialized = false;

  constructor(name: string, storeName: string) {
    super(name, storeName);
    this.replacer = this.replacer.bind(this);
    this.reviver = this.reviver.bind(this);
  }

  /**
   * Retrieves the data associated with the mountKey from storage.
   * Ensures that the storage directory is created before accessing the data.
   *
   * @returns {Promise<Stringified<string>>} A promise that resolves to the data, or null if not found.
   */
  async get(): Promise<TActivityStorage> {
    if (!this.isInitialized) await this.initStorage();
    const storageString = await super.getDatabaseItem(this.mountKey);
    return storageString ? JSON.parse(storageString, this.reviver) as TActivityStorage : new Map();
  }

  /**
   * Stores the provided value under the mountKey in storage.
   * Ensures that the storage directory is created before storing the data.
   *
   * @param {TActivitySpace | null} value - The data to store in the storage.
   */
  async set(value: TActivityStorage | null): Promise<void> {
    if (!this.isInitialized) await this.initStorage();
    if (value) {
      const storageString = JSON.stringify(
        value,
        this.replacer
      ) as Stringified<TActivityStorage> | null;
      await super.setDatabaseItem(this.mountKey, storageString);
    } else {
      await super.setDatabaseItem(this.mountKey, null);
    }
  }

  // # Helper Functions
  private replacer(key: string, value: any): any {
    if (value instanceof Map) {
      return {
        type: "Map",
        value: Array.from(value.entries()),
      };
    } else if (value instanceof Set) {
      return {
        type: "Set",
        value: Array.from(value),
      };
    }
    return value;
  }

  private reviver(key: string, value: any): any {
    if (value && typeof value === "object" && value.type === "Map") {
      return new Map(
        value.value.map(([k, v]: [any, any]) => [k, this.reviver(k, v)])
      );
    } else if (value && typeof value === "object" && value.type === "Set") {
      return new Set(value.value);
    }
    return value;
  }

   private async createWksDIR(folder: string): Promise<boolean> {
    const dir = await getDir("");
    if (!dir.find((x) => x.name === folder)) {
      await createFolder(folder);
    }
    return true;
  }
  private async initStorage(): Promise<void> {
    await this.createWksDIR(this.folderName);
    this.isInitialized = true;
  }
}

export { ActivityStorage };
