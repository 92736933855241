import {
  IActivity,
  IFile,
} from "@/modules/a.worksheets/stores/worksheetDataStore";
import { inspect } from "@/persistentStorage/wks_storage/customStringify";
import { ActivityStorage } from "@/persistentStorage/wks_creation_storage/WksCreationStorage";
import { writeFile, deleteFile, readFileAtivity } from "@/helpers/filesReadWrite";

interface IActivityStorageApi {
  // Wipes the storage of all files
  clear(activityUuid: string): Promise<boolean>;
  // When a activity gets selected we shall call this method
  load(activity: IActivity): Promise<boolean>;
  // When a activity is unselected - and we are not finished with creation - we shall call this method
  save(activity: IActivity): Promise<boolean>;
}

class ActivityStorageManager implements IActivityStorageApi {
  private storage: ActivityStorage;

  constructor() {
    this.storage = new ActivityStorage(
      "worksheets-creation-db",
      "worksheets-creation-db"
    );
    this.storage.set(null);
  }
  public async clear(): Promise<boolean> {
    const uriMap = await this.storage.get();
    if (!uriMap) return false;

    for (const uri of uriMap.values()) {
      try {
        await deleteFile(uri);
      } catch (e) {
        console.error("Error deleting file", e);
      }
    }
    await this.storage.set(null);
    return true;
  }

  // Here we load a activity which might contain local iFiles which file.filepath is a local URI pointing to a file inside the local storage of the app
  public async load(activity: IActivity): Promise<boolean> {
    const uriMap = await this.storage.get();
    await inspect(activity, {
      onIFile: async (file) => {
        if (file.isToUpdate) {
          const id = this.deriveIFileId(file);
          const localPath = uriMap.get(id);
          if (localPath !== undefined) {
            const readFileResult = await readFileAtivity(localPath);
            file.filepath = readFileResult.uri;
          }
        }
      },
    });

    return true;
  }
  public async save(activity: IActivity): Promise<boolean> {
    console.log("Saving activity!");
    console.log("Activity", activity);
    const uriMap = await this.storage.get();

    await inspect(activity, {
      onIFile: async (file) => {
        if (file.isToUpdate) {
          console.log("processing file: ", file);
          const localUri = file.filepath;

          const id = this.deriveIFileId(file);
          console.log("id: ", id);

          const blob = await fetch(localUri).then((r) => r.blob());
          const writeFileResult = await writeFile(id, blob);
          uriMap.set(id, writeFileResult.uri.slice(6));
          file.filepath = writeFileResult.uri.slice(6);

          URL.revokeObjectURL(localUri);
        }
      },
    });
    await this.storage.set(uriMap);
    return true;
  }

  private deriveIFileId(file: IFile): string {
    return `FILESYSTEM_${file.filename}_${file.filetype}`;
  }
}

const memoryManager = new ActivityStorageManager();
export default memoryManager;
