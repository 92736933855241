import {
  resolveMimeType,
  MimeType,
} from "@/globals/helpers/media/mimeTypeUtils";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

//- Config
const FILESYSTEM_DIRECTORY = Directory.Data;
const ENCODING = Encoding.UTF8;
// --

const blobToBase64 = (blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });

function base64ToBlob(base64: string, mimeType: string) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return blob;
}

const writeFile = async (filename: string, base64Data: string | Blob) => {
  // In case we only want to handle base64 strings
  if (typeof base64Data !== "string") {
    base64Data = await blobToBase64(base64Data);
  }

  console.log("writing file with name: ", filename);
  if(typeof base64Data === "object"){
    console.log("writing file with data: ", base64Data)
  }

  //console.log("writing file: ", filename);
  //console.log("write file data: ", base64Data.slice(0, 100) + "...");
  const uri = await Filesystem.writeFile({
    path: filename,
    data: base64Data,
    directory: FILESYSTEM_DIRECTORY,
    recursive: true, // Whether to create any missing parent directories.
    encoding: ENCODING, // If we use Encoding or otherwise the base64 metadata gets lost (exp.: data:image/png;base64,[...])
  });
  //! Careful! The uri contains the data path which will need to be removed when 'readFile'!
  return uri;
};

const getFile = async (filename: string) => {
  //console.log("Reading file: ", filename);
  const data = await Filesystem.readFile({
    path: filename,
    // directory: FILESYSTEM_DIRECTORY,
    encoding: ENCODING,
  });
  //console.log("Read file data: ", data);
  return data;
};

const readFile = async (
  filename: string
): Promise<{ uri: string; type: MimeType | undefined }> => {
  const data = await Filesystem.readFile({
    path: filename,
    directory: FILESYSTEM_DIRECTORY, //TODO: THIS SHOULD BE DISABLED AS IT CAUSES PROBLEMS!
    encoding: ENCODING,
  });

  if (typeof data.data === "string") {
    const mimeTypeString = resolveBase64Type(data.data);
    const mimeType = resolveMimeType(mimeTypeString);

    return { uri: data.data, type: mimeType };
  } else {
    const blob: Blob = data.data;
    const mimeType = resolveMimeType(blob.type);

    return { uri: URL.createObjectURL(blob), type: mimeType };
  }
};

//Update para funcionar mudar atividades
const base64ToBlobNew = (base64: string, mimeType: string) => {
  const byteCharacters = atob(base64.split(',')[1]); // Make sure to split out the base64 metadata
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length).fill(null).map((_, i) => slice.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

// Read para actividades resolução para quando mudo de actividades e volto para a antiga dar display
const readFileAtivity = async (filename: string) => {
  console.log("Reading file:", filename);
  const result = await Filesystem.readFile({
    path: filename,
    directory: FILESYSTEM_DIRECTORY,
    encoding: ENCODING,
  });

  const mimeType = resolveBase64TypeNew(result.data as string);
  const blob = base64ToBlobNew(result.data as string, mimeType);
  const uri = URL.createObjectURL(blob);

  console.log("File read with type:", mimeType);
  return { uri, type: mimeType as MimeType };
};

const deleteFile = async (filename: string) => {
  console.log("Deleting file: ", filename)
  await Filesystem.deleteFile({
    path: filename,
    // directory: FILESYSTEM_DIRECTORY,
  });
};

// Todo: delete file from filesystem should be implemented as well

function resolveBase64Type(base64: string): string {
  const type = base64.split(";")[0].split(":")[1];
  return type;
}

//Update para funcionar mudar atividades
const resolveBase64TypeNew = (base64: string) => {
  return base64.substring(base64.indexOf(':') + 1, base64.indexOf(';base64'));
};


async function fileToBase64(file: File): Promise<string | undefined> {
  return await new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string | undefined);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export { readFile, writeFile, blobToBase64, getFile, deleteFile, base64ToBlob, fileToBase64, readFileAtivity };
