import requestService from "@/services/requests";
import { AxiosResponse } from "axios";
import { IAssessment } from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";
import { IFile } from "../stores/worksheetDataStore";
import { nanoid } from "nanoid";

// equals IOpenEndedAnswer in wksUserAnswerDataStore
type WorksheetProblemResolutionResponseOpen = {
  uuid: string;
  input_type: number;
  answer_file: Array<{
    uuid: string;
    file: IFile;
    order: number;
    textfield: string;
  }>;
  answer_assessment: Array<IAssessment>;
  router: string;
  labels: string;
  solved_at: number;
};

type WorksheetProblemResolutionResponseClosed = {
  student_answer: string;
  solved_at: number;
  router: string;
};

type MultipleChoicesData = {
  scale: Array<string>;
  scaleLevels: Array<string>;
  description: Array<string>;
};

class WorksheetService {
  // ------- POST SOLUTIONS ---------
  postProblemResolutionChoices(
    user_wks_uuid: string,
    problem_uuid: string,
    char: string
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseClosed>> {
    const formData = new FormData();
    formData.append("user_wks_status", user_wks_uuid);
    formData.append("problem", problem_uuid);
    formData.append("choice", char);

    return requestService.post<WorksheetProblemResolutionResponseClosed>(
      "worksheet-problem-resolution-choices2",
      formData
    ); //or formData
  }
  postProblemResolutionYesNo(
    wks_uuid: string,
    problem_uuid: string,
    bool: boolean
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseClosed>> {
    return requestService.post("worksheet-problem-resolution-yesno2", {
      user_wks_status: wks_uuid,
      problem: problem_uuid,
      choice: bool,
    });
  }
  postProblemResolutionText(
    user_wks_uuid: string,
    problem_uuid: string,
    rtfText: string
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen>> {
    const formData = new FormData();
    formData.append("user_wks_status", user_wks_uuid);
    formData.append("problem", problem_uuid);
    formData.append("solution", rtfText);

    return requestService.post<WorksheetProblemResolutionResponseOpen>(
      `worksheet-problem-resolution-text2`,
      formData
    );
  }
  postProblemResolutionPhoto(
    user_wks_uuid: string,
    problem_uuid: string,
    files: Array<File>
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen>> {
    const formData = new FormData();
    formData.append("user_wks_status", user_wks_uuid);
    formData.append("problem", problem_uuid);
    appendFilesToFormData(formData, files);

    return requestService.post<WorksheetProblemResolutionResponseOpen>(
      `worksheet-problem-resolution-photo2`,
      formData
    );
  }
  postProblemResolutionVideo(
    wks_uuid: string,
    problem_uuid: string,
    files: Array<File>
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen>> {
    const formData = new FormData();
    formData.append("user_wks_status", wks_uuid);
    formData.append("problem", problem_uuid);
    appendFilesToFormData(formData, files);

    return requestService.post<WorksheetProblemResolutionResponseOpen>(
      `worksheet-problem-resolution-video2`,
      formData
    );
  }
  postProblemResolutionAudio(
    wks_uuid: string,
    problem_uuid: string,
    files: Array<File>
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen>> {
    const formData = new FormData();
    appendFilesToFormData(formData, files);
    formData.append("user_wks_status", wks_uuid);
    formData.append("problem", problem_uuid);

    return requestService.post<WorksheetProblemResolutionResponseOpen>(
      `worksheet-problem-resolution-audio2`,
      formData
    );
  }
  postProblemResolutionPdf(
    wks_uuid: string,
    problem_uuid: string,
    files: Array<File>
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen>> {
    const formData = new FormData();
    appendFilesToFormData(formData, files);
    formData.append("user_wks_status", wks_uuid);
    formData.append("problem", problem_uuid);

    return requestService.post<WorksheetProblemResolutionResponseOpen>(
      `worksheet-problem-resolution-pdf2`,
      formData
    );
  }
  postProblemResolutionDocument(
    wks_uuid: string,
    problem_uuid: string,
    files: Array<File>
  ): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen>> {
    const formData = new FormData();
    appendFilesToFormData(formData, files);
    formData.append("user_wks_status", wks_uuid);
    formData.append("problem", problem_uuid);

    return requestService.post<WorksheetProblemResolutionResponseOpen>(
      `worksheet-problem-resolution-document2`,
      formData
    );
  }

  // Self-Assessment

  postSelfAssessment(answer_uuid: string, scale: number, grade: string) {
    const formData = new FormData();
    formData.append("answer", answer_uuid);
    formData.append("evaluation_scale", scale.toString());
    formData.append("grade", grade);

    return requestService.post<Array<IAssessment>>(
      `worksheet-self-assessment`,
      formData
    );
  }
  postPeerAssessment(answer_uuid: string, scale: number, grade: string) {
    const formData = new FormData();
    formData.append("answer", answer_uuid);
    formData.append("evaluation_scale", scale.toString());
    formData.append("grade", grade);

    return requestService.post(`worksheet-peer-assessment`, formData);
  }
  postTeacherAssessment(answer_uuid: string, scale: number, grade: string) {
    const formData = new FormData();
    formData.append("answer", answer_uuid);
    formData.append("evaluation_scale", scale.toString());
    formData.append("grade", grade);

    return requestService.post(`worksheet-teacher-assessment`, formData);
  }

  // Get N-Options
  getMultipleChoices(
    nOptions: number
  ): Promise<AxiosResponse<MultipleChoicesData>> {
    return requestService.getWithParams<MultipleChoicesData>(
      `get-qualitative-scale`,
      { n_options: nOptions }
    );
  }
  postFeedback(
    wks_uuid: string,
    rate: number,
    comment: string,
    files: Array<File>
  ) {
    const formData = new FormData();

    formData.append("user_wks_status", wks_uuid);
    formData.append("rate", rate.toString());
    formData.append("comment", comment);
    appendFilesToFormData(formData, files);

    return requestService.post("worksheet-student-feedback", formData);
  }
  postFoundError(
    wks_uuid: string,
    problem_uuid: string,
    msg: string,
    files: Array<File>
  ) {
    const formData = new FormData();
    // api/worksheet-problem-error-found/ {wks: uuid, problem: uuid, message: Text }+file

    formData.append("wks", wks_uuid);
    formData.append("problem", problem_uuid);
    formData.append("message", msg);
    appendFilesToFormData(formData, files);

    return requestService.post("worksheet-problem-error-found", formData);
  }
  postNewSolution(
    wks_uuid: string,
    problem_uuid: string,
    data: Array<File> | string
  ) {
    const formData = new FormData();

    formData.append("wks", wks_uuid);
    formData.append("problem", problem_uuid);
    if (typeof data === "string") {
      formData.append("message", data);
    } else {
      appendFilesToFormData(formData, data);
      formData.append("message", "");
    }

    return requestService.post("worksheet-problem-new-solution", formData);
  }
}
const worksheetService = new WorksheetService();

function appendFilesToFormData(formData: FormData, files: Array<File>) {
  const fileIds: string[] = [];
  files.forEach((f) => {
    const id = nanoid();
    fileIds.push(id);
    formData.append(id, f);
  });
  formData.append("files", JSON.stringify(fileIds));
}

export {
  worksheetService,
  WorksheetProblemResolutionResponseOpen,
  WorksheetProblemResolutionResponseClosed,
};
