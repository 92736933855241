
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';
import { Motion } from '@capacitor/motion';
import { ScreenOrientation } from '@capacitor/screen-orientation';

import pinia from "@/plugins/piniaStore";
import { useDeviceStatus } from "@/stores/deviceStatus";

const deviceStatus = useDeviceStatus(pinia);

async function netListener() {
    const status = await Network.getStatus()
    deviceStatus.isNetConnected = status.connected;
    await Network.addListener('networkStatusChange', status => {
        console.warn("Net status changed: ", status.connected);
        deviceStatus.isNetConnected = status.connected;
    })
}

async function orientationListener() {
    const status = await ScreenOrientation.orientation();
    console.log('ScreenOrientation:', status);
    deviceStatus.orientation = status.type;
    await ScreenOrientation.addListener('screenOrientationChange', (status) => {
        deviceStatus.orientation = status.type;
    });
}

function appStateChange() {
    App.addListener('appStateChange', ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
    });
}

function backButtonListener() {
    App.addListener('backButton', status => {
        console.log("BackButonListener", status);
    })
}

export { backButtonListener, appStateChange, netListener, orientationListener }