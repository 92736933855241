import { defineStore } from "pinia";
import { DeviceInfo, Device } from "@capacitor/device";
import { getPlatforms } from "@ionic/vue";

interface DeviceStatusState {
  platform: "web" | "mobile";
  mode: "development" | "production";
  isNetConnected: boolean;
  language: string;
  isDarkMode: boolean;
  offLineMode: boolean;
  currentOrientation: number;
  orientation:string,
  memUsed: number;
  diskFree: number;
  diskTotal: number;
  errorMsg: string;
  deviceInfo: object;
  invalidVersion: {
    message: string[]
  } | undefined;
}

type MemorySizes = "Byte" | "KB" | "MB" | "GB";

export const useDeviceStatus = defineStore({
  id: "DeviceStatus",
  state: (): DeviceStatusState => {
    return {
      platform: "web",
      mode: "production",
      isNetConnected: true,
      language: "EN",
      isDarkMode: false,
      offLineMode: false,
      currentOrientation: 0,
      orientation:'portrait',
      memUsed: 0,
      diskFree: 0,
      diskTotal: 0,
      errorMsg: "None",
      deviceInfo:{},
      invalidVersion: undefined
    };
  },
  getters: {
    getPlatform: (state) => state.platform,
    getHasNet: (state) => state.isNetConnected,
    getLanguage: (state) => state.language,
    isDarkmode: (state) => state.isDarkMode,
    isOffLine: (state) => state.offLineMode,
    isWeb: (state) => state.platform === "web",
    getDiskFree: (state) => (unit: MemorySizes) => {
        return formatMemorySize(state.diskFree, "Byte", unit).toFixed(2);
    },
    getDiskTotal: (state) => (unit: MemorySizes) => {
        return formatMemorySize(state.diskTotal, "Byte", unit).toFixed(2);
    },
    getMemUsed: (state) => (unit: MemorySizes) => {
        return formatMemorySize(state.memUsed, "Byte", unit).toFixed(2);
    }
  },
  actions: {
    setNetConnected(status: boolean) {
      this.isNetConnected = status;
    },
    setCurrentOrientation(status: number) {
      this.currentOrientation = status;
    },
    setPlatform(platform: "web" | "mobile") {
      const body = document.querySelector("body");
      const html = document.querySelector("html");
      if (platform === "web") {
        body?.classList.remove("mobile");
        body?.classList.add("web");

        html?.classList.remove("mobile");
        html?.classList.add("web");
      } else {
        body?.classList.remove("web");
        body?.classList.add("mobile");

        html?.classList.remove("web");
        html?.classList.add("mobile");
      }
      this.platform = platform;
    },
    async refresh() {
      const device_info: DeviceInfo = await Device.getInfo();
      this.memUsed = device_info.memUsed ?? 0;
      this.diskFree = device_info.realDiskFree ?? 0;
      this.diskTotal = device_info.realDiskTotal ?? 0;
      this.deviceInfo = device_info ?? {};

      const platform = getPlatforms();
      const plt = platform.includes("ios") || platform.includes("android") ? "mobile": "web";
      if (plt === "web") {
        this.setPlatform("web");
      } else {
        this.setPlatform("mobile");
      }
    },
  },
});


function formatMemorySize(size: number, from: MemorySizes, to: MemorySizes): number {
    const units: MemorySizes[] = ["Byte", "KB", "MB", "GB"];
    const indexFrom = units.indexOf(from);
    const indexTo = units.indexOf(to);

    if (indexFrom === -1 || indexTo === -1) throw new Error("Invalid memory size unit");
    let newSize = 0; 

    const unitDifference = indexTo - indexFrom;
    if (unitDifference === 0) {
        newSize = size;
    } else if (unitDifference > 0) {
        newSize = size / Math.pow(1024, unitDifference);
    } else {
        newSize = size * Math.pow(1024, -unitDifference);
    }
    return newSize;
}
