import { defineStore } from "pinia";
import { EvaluationScale } from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";

interface IWorksheet {
  uuid: string; //worksheet.uuid
  name: string;
  wks_type: number;
  wks_type_str: string;

  teacher: string; //the owner of the worksheet

  difficulty_level: number;
  difficulty_level_str: string;

  creation_phase: number;

  extra_info: IFile | null;
  learning_scenario: IFile | null;

  selectedTaskUUID: string; // uuid of the selected task
  selectedActivityUUID: string; // uuid of the selected activity

  tasks: Array<ITask0>;
}

interface IFile {
  filename: string;
  filetype: string;
  filepath: string;
  isLocal?: boolean;
}

interface IFileGroup {
  uuid: string;
  order: number;
  file: IFile;
}

interface ITask0 {
  order: number;
  task: ITask;
}
interface ITask {
  uuid: string; //task
  //order: number, JR
  task_files: Array<ITaskFile> | null;
  activities: Array<IActivity>;
  isToDelete: boolean;
}
interface ITaskFile {
  uuid: string;
  order: number;
  file: IFile;
  
}
interface IActivity {
  uuid: string;
  //FK_task: string,
  order: number;
  activity_type: number; // 1-problem, 2-tutorial,
  tutorial: ITutorial | undefined;
  problem: IProblem | undefined;
  isToDelete: boolean;
}

interface ITutorial {
  uuid: string;
  tutorialFiles: Array<ITutorialFile> | null;
}
interface ITutorialFile {
  uuid: string;
  file: IFile;
  order: number;
}

enum ProblemType {
  OPEN = "open",
  TRUE_FALSE = "true-false",
  MULTIPLE_CHOICES = "multiple-choices",
  MATCH_CASE = "match-case",
  SHORT_ANSWER = "short-answer",
}

interface IProblem {
  uuid: string;
  problem_type: ProblemType; //open, multiple-choices, true-false
  max_points: number;
  teacher_solution: Array<ISolution>; // Instruction[0], Video/Media Solutions[1] Section - in WKS Creation ??
  // 
  open_ended: IOpenEnded | undefined;
  close_ended: IClosedEnded | undefined | null;
}
interface ISolution {
  uuid: string;
  order: number;
  solution_files: Array<IFileGroup | null>; // solution_files[0] -> Instruction, solution_files[1...n] -> Video/Media Solutions
}

interface IClosedEnded {
  uuid: string;
  n_options: number;
  correct_answer: string;
  correct_answer_pos: number | null; // starting at 0
}
interface IOpenEnded {
  uuid: string;
  photo: boolean;
  video: boolean;
  audio: boolean;
  text: boolean;
  pdf: boolean;
  document: boolean;
  max_num_files: number;
  max_size_mbytes: number;
  evaluation_scale: EvaluationScale;
}
interface IAnswerType {
  photo: boolean;
  video: boolean;
  audio: boolean;
  text: boolean;
  document: boolean;
  pdf: boolean;
  max_num_files: number;
  max_size_mbytes: number;
}

const useWorksheetData = defineStore({
  id: "WorksheetDataModule",
  state: (): IWorksheet => ({
    uuid: "",
    selectedTaskUUID: "",
    selectedActivityUUID: "",
    name: "",
    difficulty_level: 1,
    difficulty_level_str: "",
    wks_type: 1,
    wks_type_str: "",
    extra_info: { filename: "", filetype: "", filepath: "" },
    learning_scenario: { filename: "", filetype: "", filepath: "" },
    creation_phase: 0,
    teacher: "",
    tasks: [
      {
        // uuid: '1', //task
        order: 1,
        task: {
          uuid: "", // 1 with only 1 activity
          task_files: [
            {
              uuid: "",
              order: 1,
              file: { filename: "", filetype: "", filepath: "", },
            },
          ],
          // https://milage.ualg.pt/milv2media/documents/pt/pt99/c0685t5115w00009482q00023477.pdf
          activities: [
            {
              uuid: "1",
              order: 1,
              activity_type: 2, // 1-problem, 2-tutorial,
              //FK_task: "",
              tutorial: {
                uuid: "",
                tutorialFiles: [
                  {
                    uuid: "",
                    order: 1,
                    file: {
                      filename: "",
                      filetype: "",
                      filepath: "",
                    },
                  },
                ],
              },
              problem: undefined,
              isToDelete: false,
            },
          ],
          isToDelete: false,
        },
      },
    ],
  }),
  getters: {
    getTasksList: (state): Array<string> => {
      // getTasksList
      const taskList: Array<string> = [];
      if(state.tasks){
        for (let i = 0; i < state.tasks.length; i++) {
          taskList.push(state.tasks[i].task.uuid);
        }
      }
      return taskList;
    },
    getActivitiesList: (state): Array<string> => {
      const activitiesList: Array<string> = [];
      const task: ITask0 | undefined = state.tasks?.find(
        (item) => item.task.uuid === state.selectedTaskUUID
      );
      if (!task) {
        console.error(
          `ERROR: selectedTaskUUID ${state.selectedTaskUUID} can't be found in tasks!`
        );
        return [];
      }
      for (let i = 0; i < task.task.activities.length; i++) {
        activitiesList.push(task.task.activities[i].uuid);
      }
      return activitiesList;
    },
    getActivity:
      (state) =>
      (taskUUID: string, activityUUID: string): IActivity | undefined => {
        const task: ITask0 | undefined = state.tasks?.find(
          (item) => item.task.uuid === taskUUID
        );
        if (!task) {
          console.error(`ERROR: taskUUID: ${taskUUID} missing`);
          return undefined;
        }
        const activity: IActivity | undefined = task.task.activities.find(
          (item) => item.uuid === activityUUID
        );
        return activity;
      },
    getTask:
      (state) =>
      (taskUUID: string): ITask0 | undefined => {
        const task: ITask0 | undefined = state.tasks?.find(
          (item) => item.task.uuid === taskUUID
        );
        if (!task) {
          console.error(
            `ERROR: taskUUID: ${taskUUID} can't be found in tasks!`
          );
          return undefined;
        }
        return task;
      },
    getCurrentTask: (state): ITask0 | undefined => {
      // eslint-disable-next-line
      const task: ITask0 = state.tasks?.find(
        (item) => item.task.uuid === state.selectedTaskUUID
      )!; // A Task is always selected
      return task;
    },
    getCurrentActivity: (state): IActivity | undefined => {
      
      const task: ITask0 | undefined = state.tasks?.find(
        (item) => item.task.uuid === state.selectedTaskUUID
      ); 
      const activity: IActivity | undefined = task?.task.activities.find(
        (item) => item.uuid === state.selectedActivityUUID
      ); 
      return activity;
    },
  },
  actions: {
    async getWorksheet(wks_uuid: string) {
      //const resp = getWorksheetStatus.get(wks_uuid, usr_uuid)
      return 0;
    },
    selectTask(taskUUID: string): boolean {
      this.selectedTaskUUID = taskUUID;
      const task = this.getTask(taskUUID);
      if (task) {
        this.selectActivity(task.task.activities[0].uuid);
        return true;
      } else return false;
    },
    selectActivity(activityUUID: string): boolean {
      const isTask = this.getTask(this.selectedTaskUUID)?.task.activities.find(
        (item) => item.uuid === activityUUID
      );
      if (isTask) {
        const act = this.getActivity(this.selectedTaskUUID, activityUUID);
        if (act) this.selectedActivityUUID = activityUUID;
        return true;
      } else return false;
    },
    // isIWorksheet(obj: any){
    //     return (
    //         obj &&
    //         obj.uuid === "string" &&
    //         obj.name === "string" &&
    //         obj.wks_type === "number" &&
    //         obj.wks_type_str === "string" &&
    //         obj.teacher === "string" &&
    //         obj.difficulty_level === "number" &&
    //         obj.difficulty_level_str === "string" &&
    //         obj.creation_phase === "number" &&
    //         obj.extra_info === this.isIFile(obj.extra_info) &&
    //         obj.learning_scenario === this.isIFile(obj.learning_scenario) &&
    //         obj.selectedTaskUUID === "string" &&
    //         obj.selectedActivityUUID === "string" &&
    //         obj.tasks === "Array<ITask0>"
    //     )
    // },
    // isIFile(obj: any){
    //     return (
    //         obj &&
    //         obj.filename === "string" &&
    //         obj.filetype === "string" &&
    //         obj.filepath === "string"
    //     )
    // }
  },
});

export {
  useWorksheetData,
  IWorksheet,
  ITaskFile,
  ITask,
  IActivity,
  IProblem,
  ITutorial,
  IAnswerType,
  ISolution,
  IFile,
  ITutorialFile,
  ITask0,
  ProblemType,
  IOpenEnded,
  IClosedEnded,
  EvaluationScale,
  IFileGroup,
};
