//import { labelsDataDB } from "@/persistentStorage/appInterface";
import { usePageLayouts, IPageLayoutObj } from "@/stores/pageLayoutsStore";

import { iconsLocalFiles } from "@/persistentStorage/iconsLocalFiles";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { readImageFile, readUTFFile } from "@/helpers/imagesReadWrite";
import requestService from "@/services/requests";

import { url } from "@/stores/urls";

import { readFile, writeFile, blobToBase64, getFile as getLocalFile } from "@/helpers/filesReadWrite";

import { iconsFilesDB } from "@/persistentStorage/iconsStorageFiles";
import { reactive, UnwrapNestedRefs, ComputedRef, computed } from "vue";
import { MimeType } from "@/globals/helpers/media/mimeType";
import { resolveMimeType } from "@/globals/helpers/media/mimeTypeUtils";
import { IFile } from "@/modules/j.createWorksheets/stores/createWksStore";
import { resolvedIFileNewVersion } from "@/modules/j.createWorksheets/helpers/fileHandler";


async function getIcon(screen: string, key: string) {
  const deviceStatus = useDeviceStatus();
  let id = `${screen}_`;
  if (deviceStatus.isDarkMode) {
    id = id + `iconsdark_${key}`;
  } else {
    id = id + `icons_${key}`;
  }

  const data = await iconsFilesDB.loadFile(id);
  const info = await iconsLocalFiles.getIconInfo(id);
  //const data = await readFile("milage_icons/"+id).then( r => r.data);
  if (info !== null) {
    //const dataSrc = `data:${info.type};base64, ${data}` as string
    const dataSrc = `${data}` as string;
    return dataSrc;
  } else {
    // console.warn(
    //   `Icon with screenID: ${screen} and key: ${key} is not available`
    // );
  }
}


//=======================================

export enum ImageEncoding {
  SVG = "svg",
  BASE64 = "base64",
}

interface IPageLayout {
  status: boolean;
  labels: object;
  helps: object;
  errors: object;
  commands: object;
  //icons:object
}

const pageLayouts = usePageLayouts();

async function getPageLayout<T = IPageLayout>(
  screen_id: string
): Promise<UnwrapNestedRefs<T>> {
  //return a specific page layout by its id
  //const ll = await labelsDataDB.getPageLayout<T>(screen_id);
  const pg = pageLayouts.getPageLayout(screen_id) as IPageLayoutObj;
  const out = reactive({
    status: true,
    labels: pg.labels,
    helps: pg.helps,
    errors: pg.errors,
    commands: pg.commands,
  }) as UnwrapNestedRefs<T>;

  return out;
}

function getComputedPageLayout<T = IPageLayout>(
  screen_id: string
): ComputedRef<T | undefined> {
  //return a specific page layout by its id
  return computed(() => {
    const pg = pageLayouts.getPageLayout(screen_id);
    if (pg) {
      return {
        status: true,
        labels: pg.labels,
        helps: pg.helps,
        errors: pg.errors,
        commands: pg.commands,
      } as T;
    } else {
      return undefined;
    }
  });
}


/**
 * Fetches a file from the milage server and returns a URL for the file blob.
 *
 * This function attempts to retrieve a file specified by its path and name from
 * the milage server. If the request is successful, it creates a blob URL for the
 * fetched file, which can be used to access or download the file in the client.
 * In case the request fails or the server responds with a status outside of the
 * 200-299 range, the function returns `undefined`.
 *
 * @param {string} filepath - The path of the file on the server, excluding the filename.
 * @param {string} filename - The name of the file, including its extension.
 * @param {string} filetype - The MIME type of the file. Currently unused but could be
 *                            used for setting the response type or validating the file.
 * @returns {Promise<string | undefined>} A promise that resolves to a blob URL string
 *                                        of the fetched file if successful, or `undefined`
 *                                        if the fetch fails or the server response is not OK.
 */
async function getFile(
  filepath: string,
  filename: string,
  filetype: string
): Promise<string | undefined> {
  if(isEmpty({filepath, filename, filetype} as IFile)) return undefined;
  const url0 = `${url.mediaURL}${filepath}/${filename}`;
  //const url0 = `${url.getMedia}${filepath}/${filename}`;
  try {
    const resp = await fetch(url0);
    if (resp.status >= 200 && resp.status < 300) {
      const blob = await resp.blob();
      const url2 = URL.createObjectURL(blob);
      return url2;
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}

async function convertIFileToFile(filepath: string, filename: string, filetype: string): Promise<File | undefined>{
  const url0 = `${url.mediaURL}${filepath}/${filename}`;
  try {
    const resp = await fetch(url0);
    if (resp.status >= 200 && resp.status < 300) {
      const blob = await resp.blob();
      const mimeType: MimeType | undefined = resolveMimeType(filetype);
      if(!mimeType) throw new Error(`${filetype} is not a valid MIME type.`);

      const file = new File([blob], filename, { type: filetype });
      return file;
    } else {
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  } 
}

function fileToIFile(file: File): IFile {
  const url = URL.createObjectURL(file);
  return {
    filename: file.name,
    filetype: file.type,
    filepath: url,
    isToUpdate: true,
    isToDelete: false
  };
}



async function getMediaFile(
  filepath: string,
  filename: string,
  filetype: string
): Promise<string> {
  let blobUrl = await getLocalMediaFile(filepath, filename, filetype);
  if (!blobUrl) {
    blobUrl = await getRemoteMediaFile(filepath, filename, filetype) ?? null;
  }

  return blobUrl ?? '';
}

async function getLocalMediaFile(
  filepath: string,
  filename: string,
  filetype: string
): Promise<string | null> {
  try {

    const localFile = await getLocalFile(`${filepath}/${filename}`);

    let blobUrl = "";
    if (typeof localFile.data === "string") {
      // convert base64 to blob
      const base64 = localFile.data.split(",")[1];
      const type = localFile.data.split(",")[0].split(";")[0].split(":")[1];

      // Decode base64 to binary
      const binary = atob(base64);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      console.log("pushed array: ", array)
      const blob = new Blob([new Uint8Array(array)], { type: type });

      blobUrl = URL.createObjectURL(blob);
    } else {
      // parse blob
      blobUrl = URL.createObjectURL(localFile.data);
    }
    return blobUrl;
  } catch (e) {
    // file not found
    return null;
  }
}

async function getRemoteMediaFile(
  filepath: string,
  filename: string,
  filetype: string
): Promise<string | undefined> {
  const resp = await requestService.getServerMediaFile<ArrayBuffer>(
    filepath,
    filename,
    filetype
  );
  const type = resp.headers["content-type"];
  if (!type){
    console.error("api.ts -> getMediaFile Function -> type is undefined!");
    return undefined
  }
  const blob = new Blob([resp.data], { type: type });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
}
/*
 Lets assume we have a url pointing towards a mp4 movie. What is the difference between first downloading the movie and creating a blob + blobURL or directly linking the URL to a <video> tag?
*/

function arrayBufferToBase64(
  buffer: ArrayBuffer,
  type: MimeType
): Promise<string> {
  return new Promise((resolve, reject) => {
    const blob = new Blob([buffer], { type });
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64data = reader.result;
      if (typeof base64data !== "string") reject("base64data is not a string");
      resolve(base64data as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

function base64ToBlob(base64: string, contentType: string) {
  // Decode the Base64 string
  const byteCharacters = atob(base64);

  // Create an array of bytes
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Convert to a byte array
  const byteArray = new Uint8Array(byteNumbers);

  // Create a blob with the specified content type
  return new Blob([byteArray], { type: contentType });
}

const isLocalIFile = (iFile: IFile) => iFile.filepath.startsWith("blob:")
async function getIFileUri(file: IFile): Promise<string> {
  if (isLocalIFile(file)) {
    return file.filepath; 
  }else{
    //const resolvedSrc = await getFile(file.filepath, file.filename, file.filetype);
    // const resolvedSrc = url.getMedia + file.filepath + "/" + file.filename;
    const resolvedSrc = await resolvedIFileNewVersion(file, true)
    console.log("ResolvedSrc", resolvedSrc, "File:", file)
    if(!resolvedSrc) throw new Error("File not found");
    return resolvedSrc;
  }
}

function isEmpty(file: IFile): boolean{
  if(!file.filepath || !file.filename || !file.filetype) return true;
  else return false;
}

const fileCache: Record<string, File> = {}; 

async function fetchLocalIFile(iFile: IFile): Promise<File | undefined> {
    const cacheKey = iFile.filepath;
    if (fileCache[cacheKey]) {
        return fileCache[cacheKey];
    }
    try {
        const response = await fetch(iFile.filepath);
        const blob = await response.blob();
        const file = new File([blob], iFile.filename);
        fileCache[cacheKey] = file;
        return file;
    } catch (error) {
        console.error('Failed to fetch and create file:', error);
        return undefined;
    }
}
const deriveIFileId = (file: IFile): string => `${file.filepath}/${file.filename}`; // another options would be to use nanoid() to generate a unique id

export {
  getPageLayout,
  getComputedPageLayout,
  getIcon,
  getFile,
  IPageLayout,
  getMediaFile,
  arrayBufferToBase64,
  getLocalMediaFile,
  getRemoteMediaFile,
  convertIFileToFile,
  isLocalIFile,
  getIFileUri,
  fileToIFile,
  fetchLocalIFile,
  deriveIFileId
};
