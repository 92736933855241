<template>
  <div class="pdf-viewer">


    <pdf-controls
      v-if="isControl && (controlOptions.isDelete || controlOptions.isDownload || controlOptions.isMove )"
      :isMove="controlOptions.isMove"
      :isDelete="controlOptions.isDelete"
      :isDownload="controlOptions.isDownload"
      :page="page"
      :pageCount="pageCount ?? undefined"
      @delete="deleteFile"
      @download="downloadFile"
      @prev="prev"
      @next="next"
      @expand="$emit('click', source)"
    />


    <div :style="[getWrapperStyles, { overflow: 'hidden', height: '100%' }]">
      <div
        class="scroll-container"
        :style="[getIsScroll]"
        @scroll="scrollEvent"
        @touchmove="scrollEvent"
        @click="e => !isWeb ? handleClick : null"
        ref="scrollContainer"
      >
        <div class="fixed-width" :style="fixedWidth" ref="fixedWidthRef">
          <CustomZoomer
            v-if="isLoaded && zoomable"
            :limitTranslation="false"
            :zoomed="isZoomed"
            @update:zoomed="setIsZoomed"
            :mouseWheelToZoom="getCanZoomWithMouseWheel"
            ref="vZoomerRef"
            :rotation="rotation"
          >
            <div
              :style="
                state === State.CENTERED
                  ? {
                      height: '100vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : undefined
              "
            >
              <div>
                <div v-if="isZoomed" class="overlay"></div>

                <div
                  ref="pdfContainerRef"
                  id="pdf-container"
                  :style="fixedWidth"
                ></div>
              </div>
            </div>
          </CustomZoomer>
          <div v-else>
            <div
              :style="
                state === State.CENTERED
                  ? {
                      height: '100vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : undefined
              "
            >
              <div>
                <div
                  ref="pdfContainerRef"
                  id="pdf-container"
                  :style="fixedWidth"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onUnmounted,
  watch,
  shallowRef,
  onMounted,
  nextTick,
  StyleValue,
PropType,
toRefs,
} from "vue";
import * as pdfjsLib from "pdfjs-dist";
import { getIcon } from "@/helpers/api";
import {
  PDFDocumentProxy,
  PDFPageProxy,
  PageViewport,
  AnnotationLayer,
} from "pdfjs-dist";

import { PDFLinkService, LinkTarget } from "pdfjs-dist/web/pdf_viewer.js";
import { emptyElement, releaseChildCanvases } from "./util";
import CustomZoomer from "@/globals/modules/CustomZoomer/CustomZoomer.vue";
import { spinnerManager } from "@/globals/pages/spinnerControl";
import pdfjsWorker from "pdfjs-dist/webpack";
import { IDownloadManager } from "pdfjs-dist/types/web/interfaces";
import type { IControls, IEmits } from './model'
import { useControls } from "@/globals/components/PdfViewer/useControls";
import PdfControls from "@/globals/components/PdfViewer/PdfControls.vue";
import { IFont, getFont } from "@/helpers/fonts";
import { useDeviceStatus } from "@/stores/deviceStatus";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const props = defineProps({
  source: { type: String, required: true },
  zoomable: { type: Boolean, default: true },
  quality: { type: Number, default: 1 },
  fullSize: { type: Boolean, default: false },
  isScroll: { type: Boolean, default: true },
  page: { type: Number, default: undefined },
  rotation: { type: Number, default: 0 },
  controlOptions: { type: Object as PropType<IControls>, default: () => { return { isMove: false, isDelete: false, isDownload: false } } },
});

const emits = defineEmits<IEmits>();

enum State {
  FLOW = "flow",
  CENTERED = "centered",
}

const { isWeb } = toRefs(useDeviceStatus());
const { isMove, events } = useControls();
const state = ref<State>(State.FLOW);
const pdfContainerRef = ref<HTMLDivElement>();
const binIcon = ref<string>();
const downloadIcon = ref<string>();

const isControl = computed(() => Object.keys(props.controlOptions).some(key => props.controlOptions[key as keyof IControls]));
const prev = () => isLeftArrowGray.value ? null : emits('page-changed', (props.page ?? 0) - 1);
const next = () => isRightArrowGray.value ? null : emits('page-changed', (props.page ?? 0) + 1);
const isLeftArrowGray = computed(() => props.page === 1);
const isRightArrowGray = computed(() => props.page === pageCount.value);

const deleteFile = () => emits("delete-file");
const downloadFile = () => emits("download-file");

const nore12 = ref<StyleValue>();

const fixedWidthRef = ref<HTMLDivElement | null>(null);
const canvasContainerRef = ref<HTMLDivElement | null>(null);
const textLayerRef = ref<HTMLDivElement | null>(null);
const annotationLayerRef = ref<HTMLDivElement | null>(null);

const pageCount = ref<number | null>(null);
let pdfDocument: PDFDocumentProxy | null = null;
const internalViewport = ref<PageViewport | null>(null);

const pageHeight = ref<number>();
const pageWidth = ref<number>();

const isLoaded = ref(false);
const leftArrow = ref<string>();
const rightArrow = ref<string>();
onMounted(async () => {
  nore12.value = await getFont("nore12") as StyleValue;

  leftArrow.value = await getIcon("1602", "previous_page");
  rightArrow.value = await getIcon("1602", "next_page");
  binIcon.value = await getIcon("2105", "bin");
  downloadIcon.value = await getIcon("2105", "download");

  if (scrollContainer.value) {
    resizeObserver.observe(scrollContainer.value);
    console.warn("")
  }
  updateDimensions();
  if (scrollContainerDimensions.value.width > 0) {
    initViewer(
      scrollContainerDimensions.value.width,
      scrollContainerDimensions.value.height
    );
  } else {
    tryToSyncScrollContainerDimensions();
  }
});

function scrollEvent(e: Event) {
  if (isZoomed.value) {
    e.preventDefault();
  }
}

const getWrapperStyles = computed<StyleValue>(() => {
  if (isLoaded.value) {
    return "" as StyleValue;
  } else {
    return {
      height: "100%",
      width: "100%",
    } as StyleValue;
  }
});

const isZoomed = ref(false);
function setIsZoomed(v: boolean) {
  isZoomed.value = v;
  emits("isZoomed", v);
}
const vZoomerRef = shallowRef<any>(null);

watch(
  computed(() => props.source),
  async (newSrc, oldSrc) => {
    tryToSyncScrollContainerDimensions();
  }
);

// scrollContainer can be mounted but its computed width can still be zero
const scrollContainer = ref<HTMLDivElement | null>(null);
const scrollContainerDimensions = ref({
  width: 0,
  height: 0,
});
const updateDimensions = () => {
  if (scrollContainer.value && scrollContainer.value.clientWidth > 0) {
    scrollContainerDimensions.value = {
      width: scrollContainer.value.clientWidth,
      height: scrollContainer.value.clientHeight,
    };
  }
};

watch(
  [
    scrollContainerDimensions,
    computed(() => props.page),
    computed(() => props.source),
  ],
  async (nv, ov) => {
    if (
      scrollContainerDimensions.value.height > 0 &&
      scrollContainerDimensions.value.width > 0
    ) {
      await initViewer(
        scrollContainerDimensions.value.width,
        scrollContainerDimensions.value.height
      );
    } else {
      tryToSyncScrollContainerDimensions();
    }
  }
);

const MAX_TRIES = 100; // 10s
let isSyncing = false;
function tryToSyncScrollContainerDimensions() {
  if (isSyncing) {
    return;
  }
  isSyncing = true;
  let tries = 0;
  const interval = setInterval(() => {
    updateDimensions();
    if (scrollContainerDimensions.value.width > 0) {
      clearInterval(interval);
      isSyncing = false;
      initViewer(
        scrollContainerDimensions.value.width,
        scrollContainerDimensions.value.height
      );
    } else {
      tries++;
      if (tries > MAX_TRIES) {
        isSyncing = false;
        clearInterval(interval);
      }
    }
  }, 100);
}

let lock = false;
async function initViewer(clientWidth: number, clientHeight: number) {
  if (lock) {
    return;
  }

  lock = true;
  cleanUp();
  spinnerManager.registerTask("pdfViewer");
  try {
    const pdfAttributes = await init({
      src: props.source,
      clientWidth,
      clientHeight,
    });

    // fixedWidthRef.value?.style.setProperty(
    //   "--scale-factor",
    //   `${pdfAttributes.internalViewport.scale}`
    // );

    fixedWidthRef.value?.style.setProperty(
      "--scale-factor",
      `${pdfAttributes.internalViewport.scale}`
    );

    pageCount.value = pdfAttributes.pagesAmount;
    pageWidth.value = pdfAttributes.pageDimensions.width;
    pageHeight.value = pdfAttributes.pageDimensions.height;
    internalViewport.value = pdfAttributes.internalViewport;

    isLoaded.value = true;
    // We await until the pdfContainerRef gets mounted
    await nextTick();
    await nextTick();
    if (pdfContainerRef.value) {
      await render({
        pdfDocument: pdfAttributes.pdfDocument,
        pageCount: pdfAttributes.pagesAmount,
        pdfContainerRef: pdfContainerRef.value,
        pageWidth: pageWidth.value,
        pageHeight: pageHeight.value,
        internalViewport: internalViewport.value,
        pageNr: props.page,
      });
    } else {
      console.error("pdfContainerRef is undefined! - pdfViewer.vue");
    }
  } catch (e) {
    console.warn(e);
    spinnerManager.unregisterTask("pdfViewer");
    return;
  }

  spinnerManager.unregisterTask("pdfViewer");
  lock = false;
}



function throttle<T extends (...args: any[]) => any>(
  f: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => f(...args), delay);
  };
}

const resizeObserver = new ResizeObserver(
  throttle(tryToSyncScrollContainerDimensions, 100)
);
onUnmounted(() => {
  cleanUp();
});

function cleanUp() {
  if (pdfContainerRef.value) {
    releaseChildCanvases(pdfContainerRef.value);
    pdfContainerRef.value.innerHTML = "";
  }

  resizeObserver.disconnect();
  isLoaded.value = false;
}

async function init(params: {
  src: string;
  clientWidth: number;
  clientHeight: number;
}): Promise<{
  pageDimensions: { width: number; height: number };
  pagesAmount: number;
  internalViewport: PageViewport;
  pdfDocument: PDFDocumentProxy;
}> {
  const { clientWidth, clientHeight, src } = params;

  let pageWidth = 0;
  let pageHeight = 0;
  let pageCount = 0;
  let internalViewport: PageViewport | null = null;

  const documentLoadingTask = pdfjsLib.getDocument(src);
  pdfDocument = await documentLoadingTask.promise;
  pageCount = pdfDocument.numPages;
  const page = await pdfDocument.getPage(1);

  const pageSizes = loadAvailableDimensions(page, clientWidth);
  pageWidth = pageSizes.pageWidth;
  pageHeight = pageSizes.pageHeight;
  if (pageWidth === 0 || pageHeight === 0) {
    throw new Error("pageWidth or pageHeight is 0!");
  }
  internalViewport = loadViewport(page, pageWidth);

  events.loadedPdf(pdfDocument);
  emits("loaded", pdfDocument);

  if (internalViewport === null) {
    throw new Error("internalViewport is null!");
  }
  return {
    pageDimensions: { width: pageWidth, height: pageHeight },
    pagesAmount: pageCount,
    internalViewport: internalViewport,
    pdfDocument: pdfDocument,
  };
}

function loadViewport(page: PDFPageProxy, availableWidth: number) {
  return page.getViewport({
    scale: availableWidth / page.view[2],
  });
}

function loadAvailableDimensions(page: PDFPageProxy, clientWidth: number) {
  const width = clientWidth;
  const height = Math.floor(page.view[3] * (width / page.view[2]));
  return {
    pageWidth: width,
    pageHeight: height,
  };
}

function adjustPageNumber(pageNr: number, pageCount: number): number {
  if (pageNr < 1) {
    return pageCount - Math.abs(pageNr % pageCount);
  } else if (pageNr > pageCount) {
    return ((pageNr - 1) % pageCount) + 1;
  }
  return pageNr;
}

async function render(params: {
  pdfDocument: PDFDocumentProxy;
  pageCount: number;
  pdfContainerRef: HTMLDivElement;
  pageWidth: number;
  pageHeight: number;
  internalViewport: PageViewport;
  pageNr?: number;
}) {
  const {
    pdfDocument,
    pageCount,
    pdfContainerRef,
    pageWidth,
    pageHeight,
    internalViewport,
    pageNr,
  } = params;

  let startPage = 1;
  let endPage = pageCount;

  if (pageNr !== undefined) {
    const adjustedPageNr = adjustPageNumber(pageNr, pageCount);
    startPage = adjustedPageNr;
    endPage = adjustedPageNr;
  }

  try {
    for (let i = startPage; i <= endPage; i++) {
      const page = await pdfDocument.getPage(i);
      const pageContainer = document.createElement("div");
      pageContainer.style.width = `${pageWidth}px`;
      pageContainer.style.height = `${pageHeight}px`;
      pageContainer.style.position = "relative";

      const canvas = await renderPageCanvas(
        page,
        internalViewport,
        pageWidth,
        pageHeight
      );
      const textLayer = await renderPageTextLayer(page, internalViewport);
      const annotationLayer = await renderPageAnnotationLayer(
        page,
        internalViewport
      );

      pageContainer.appendChild(canvas);
      pageContainer.appendChild(textLayer);
      pageContainer.appendChild(annotationLayer);

      pdfContainerRef.appendChild(pageContainer);
    }
  } catch (e) {
    console.error(e);
  }
}

async function renderPageAnnotationLayer(
  page: PDFPageProxy,
  internalViewport: PageViewport
) {
  const viewport = internalViewport.clone({
    dontFlip: true,
  });

  const pdfLinkService = new PDFLinkService();
  pdfLinkService.externalLinkTarget = LinkTarget.BLANK; // Option to open links in a new tab

  const annotations = await page.getAnnotations();
  const annotationsPageDiv = document.createElement("div");
  annotationsPageDiv.classList.add("annotationLayer");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const layer = new AnnotationLayer({
    div: annotationsPageDiv,
    page: page,
    viewport: viewport,
  });

  const downloadManager: IDownloadManager = {
    downloadUrl: (url, filename): void => {
      console.warn("NOT IMPLEMENTED - downloadUrl", url, filename);
    },
    downloadData(data, filename, contentType): void {
      console.warn(
        "NOT IMPLEMENTED - downloadData",
        data,
        filename,
        contentType
      );
    },
    download(blob, url, filename): void {
      console.warn("NOT IMPLEMENTED - download", blob, url, filename);
    },
    openOrDownloadData(
      element: HTMLElement,
      data: Uint8Array,
      filename: string
    ): boolean {
      console.warn(
        "NOT IMPLEMENTED - openOrDownloadData",
        element,
        data,
        filename
      );
      return true;
    },
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  layer.render({
    annotations: annotations,
    div: annotationsPageDiv,
    linkService: pdfLinkService,
    page,
    enableScripting: true,
    renderForms: true,
    downloadManager: downloadManager,
    viewport: viewport,
  });
  return annotationsPageDiv;
}
async function renderPageTextLayer(
  page: PDFPageProxy,
  internalViewport: PageViewport
): Promise<HTMLDivElement> {
  const viewport = internalViewport.clone({
    dontFlip: true,
  });
  const textLayerDiv = document.createElement("div");
  textLayerDiv.classList.add("textLayer");

  await pdfjsLib.renderTextLayer({
    container: textLayerDiv,
    textContentSource: await page.getTextContent(),
    viewport: viewport,
  }).promise;

  return textLayerDiv;
}
async function renderPageCanvas(
  page: PDFPageProxy,
  internalViewport: PageViewport,
  pageWidth: number,
  pageHeight: number
): Promise<HTMLCanvasElement> {
  const canvas = document.createElement("canvas");
  canvas.style.width = `${pageWidth}px`;
  canvas.style.height = `${pageHeight}px`;
  const width = pageWidth;

  const viewport = page.getViewport({
    scale: Math.ceil(width / internalViewport.width) + props.quality,
  });

  canvas.width = viewport.width;
  canvas.height = viewport.height;

  await page.render({
    canvasContext: canvas.getContext("2d")!,
    viewport: viewport,
  }).promise;
  return canvas;
}

const fixedWidth = computed<StyleValue>(() => {
  if (
    pageWidth.value &&
    pageWidth.value > 0 &&
    pageHeight.value &&
    pageHeight.value > 0 &&
    pageCount.value &&
    pageCount.value > 0
  ) {
    return {
      width: `${pageWidth.value}px`,
      height:
        props.page === undefined
          ? `${pageHeight.value * pageCount.value}px`
          : `${pageHeight.value}px`,
    } as StyleValue;
  } else {
    return "" as StyleValue;
  }
});

const handleClick = (event: Event) => {
  if (props.zoomable) {
    event.stopPropagation();
  }
  emits("click", props.source);
};

// when the width of our container changes we want to reload
const availableWidth = ref<number>();
watch([availableWidth, isLoaded], async (nv, ov) => {
  const [oldSize, oldLoaded] = nv;
  const [newSize, newLoaded] = ov;

  if (oldSize !== newSize && newLoaded === true && newSize !== undefined) {
    purgeViewerDisplay();
    cleanUp();
    if (
      scrollContainerDimensions.value.height > 0 &&
      scrollContainerDimensions.value.width > 0
    ) {
      try {
        const pdfAttributes = await init({
          src: props.source,
          clientWidth: scrollContainerDimensions.value.width,
          clientHeight: scrollContainerDimensions.value.height,
        });
        fixedWidthRef.value?.style.setProperty(
          "--scale-factor",
          `${pdfAttributes.internalViewport.scale}`
        );
        pageCount.value = pdfAttributes.pagesAmount;
        pageWidth.value = pdfAttributes.pageDimensions.width;
        pageHeight.value = pdfAttributes.pageDimensions.height;
        internalViewport.value = pdfAttributes.internalViewport;

        render({
          pdfDocument: pdfAttributes.pdfDocument,
          pageCount: pdfAttributes.pagesAmount,
          pdfContainerRef: pdfContainerRef.value!,
          pageWidth: pageWidth.value!,
          pageHeight: pageHeight.value!,
          internalViewport: internalViewport.value!,
        });
      } catch (e) {
        console.warn(e);
      }
    } else {
      tryToSyncScrollContainerDimensions();
    }
  }
});

function purgeViewerDisplay() {
  // TODO: remove this method and extend cleanUp() method and call it instead
  isLoaded.value = false;

  cleanUp();
  if (annotationLayerRef.value) emptyElement(annotationLayerRef.value);
  if (textLayerRef.value) emptyElement(textLayerRef.value);
  if (canvasContainerRef.value) releaseChildCanvases(canvasContainerRef.value);

  pageWidth.value = undefined;
  pageHeight.value = undefined;

  internalViewport.value = null;
}
const getCanZoomWithMouseWheel = computed(() =>
  isZoomed.value ? true : false
);

const getIsScroll = computed(() =>
  isZoomed.value || !props.isScroll
    ? { overflow: "hidden" }
    : { overflow: "auto" }
);
const isCentered = computed(() => {
  if (fixedWidthRef.value && scrollContainer.value) {
    if (fixedWidthRef.value.clientHeight < scrollContainer.value.clientHeight) {
      return "centered-flex";
    } else {
      return "";
    }
  }
  return undefined;
});
</script>

<style scoped>
.pages-count{
  color: black;
  display: flex;
  align-items: center;
}
.pdf-viewer {
  width: 100%;
  height: 100%;
}


.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.scroll-container {
  overflow: auto;
  width: 100%;
  height: auto;
  display: flex;
}

.fixed-width {
  position: relative;
  margin: auto;
}

canvas {
  display: block;
}
</style>

<style>
/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --highlight-bg-color: rgba(180, 0, 170, 1);
  --highlight-selected-bg-color: rgba(0, 100, 0, 1);
}

@media screen and (forced-colors: active) {
  :root {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

.textLayer {
  position: absolute;
  text-align: initial;
  inset: 0;
  overflow: hidden;
  opacity: 0.25;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
}

.textLayer :is(span, br) {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

/* Only necessary in Google Chrome, see issue 14205, and most unfortunately
 * the problem doesn't show up in "text" reference tests. */
.textLayer span.markedContent {
  top: 0;
  height: 0;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer ::-moz-selection {
  background: blue;
  background: AccentColor;
}

.textLayer ::selection {
  background: blue;
  background: AccentColor;
}

/* Avoids https://github.com/mozilla/pdf.js/issues/13840 in Chrome */
.textLayer br::-moz-selection {
  background: transparent;
}
.textLayer br::selection {
  background: transparent;
}

.textLayer .endOfContent {
  display: block;
  position: absolute;
  inset: 100% 0 0;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.textLayer .endOfContent.active {
  top: 0;
}

:root {
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }
  .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
  .annotationLayer .choiceWidgetAnnotation select:required,
  .annotationLayer
    .buttonWidgetAnnotation:is(.checkBox, .radioButton)
    input:required {
    outline: 1.5px solid selectedItem;
  }

  .annotationLayer .linkAnnotation:hover {
    -webkit-backdrop-filter: invert(100%);
    backdrop-filter: invert(100%);
  }
}

.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
}

.annotationLayer[data-main-rotation="90"] .norotate {
  transform: rotate(270deg) translateX(-100%);
}
.annotationLayer[data-main-rotation="180"] .norotate {
  transform: rotate(180deg) translate(-100%, -100%);
}
.annotationLayer[data-main-rotation="270"] .norotate {
  transform: rotate(90deg) translateY(-100%);
}

.annotationLayer canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.annotationLayer section {
  position: absolute;
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
}

.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationLayer
  :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton)
  > a:hover {
  opacity: 0.2;
  background: rgba(255, 255, 0, 1);
  box-shadow: 0 2px 10px rgba(255, 255, 0, 1);
}

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  margin: 0;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required,
.annotationLayer .choiceWidgetAnnotation select:required,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input[disabled] {
  background: none;
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:hover {
  border: 2px solid var(--input-hover-border-color);
}
.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-image: none;
  background-color: transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  /*
   * Letter spacing is placed on the right side of each character. Hence, the
   * letter spacing of the last character may be placed outside the visible
   * area, causing horizontal scrolling. We avoid this by extending the width
   * when the element has focus and revert this when it loses focus.
   */
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  position: absolute;
}

.annotationLayer .popupWrapper {
  position: absolute;
  font-size: calc(9px * var(--scale-factor));
  width: 100%;
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
}

.annotationLayer .popup {
  position: absolute;
  max-width: calc(180px * var(--scale-factor));
  background-color: rgba(255, 255, 153, 1);
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))
    rgba(136, 136, 136, 1);
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  display: inline-block;
  margin-left: calc(5px * var(--scale-factor));
}

.annotationLayer .popupContent {
  border-top: 1px solid rgba(51, 51, 51, 1);
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .highlightAnnotation,
.annotationLayer .underlineAnnotation,
.annotationLayer .squigglyAnnotation,
.annotationLayer .strikeoutAnnotation,
.annotationLayer .freeTextAnnotation,
.annotationLayer .lineAnnotation svg line,
.annotationLayer .squareAnnotation svg rect,
.annotationLayer .circleAnnotation svg ellipse,
.annotationLayer .polylineAnnotation svg polyline,
.annotationLayer .polygonAnnotation svg polygon,
.annotationLayer .caretAnnotation,
.annotationLayer .inkAnnotation svg polyline,
.annotationLayer .stampAnnotation,
.annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --xfa-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --xfa-focus-outline: auto;
}

@media screen and (forced-colors: active) {
  :root {
    --xfa-focus-outline: 2px solid CanvasText;
  }
  .xfaLayer *:required {
    outline: 1.5px solid selectedItem;
  }
}

.xfaLayer {
  background-color: transparent;
}

.xfaLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: rgba(239, 203, 237, 1);
  border-radius: 4px;
}

.xfaLayer .highlight.appended {
  position: initial;
}

.xfaLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.xfaLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.xfaLayer .highlight.middle {
  border-radius: 0;
}

.xfaLayer .highlight.selected {
  background-color: rgba(203, 223, 203, 1);
}

.xfaPage {
  overflow: hidden;
  position: relative;
}

.xfaContentarea {
  position: absolute;
}

.xfaPrintOnly {
  display: none;
}

.xfaLayer {
  position: absolute;
  text-align: initial;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  line-height: 1.2;
}

.xfaLayer * {
  color: inherit;
  font: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-kerning: inherit;
  letter-spacing: -0.01px;
  text-align: inherit;
  text-decoration: inherit;
  box-sizing: border-box;
  background-color: transparent;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  line-height: inherit;
}

.xfaLayer *:required {
  outline: 1.5px solid red;
}

.xfaLayer div,
.xfaLayer svg,
.xfaLayer svg * {
  pointer-events: none;
}

.xfaLayer a {
  color: blue;
}

.xfaRich li {
  margin-left: 3em;
}

.xfaFont {
  color: black;
  font-weight: normal;
  font-kerning: none;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0;
  text-decoration: none;
  vertical-align: 0;
}

.xfaCaption {
  overflow: hidden;
  flex: 0 0 auto;
}

.xfaCaptionForCheckButton {
  overflow: hidden;
  flex: 1 1 auto;
}

.xfaLabel {
  height: 100%;
  width: 100%;
}

.xfaLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xfaRight {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

:is(.xfaLeft, .xfaRight) > :is(.xfaCaption, .xfaCaptionForCheckButton) {
  max-height: 100%;
}

.xfaTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.xfaBottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

:is(.xfaTop, .xfaBottom) > :is(.xfaCaption, .xfaCaptionForCheckButton) {
  width: 100%;
}

.xfaBorder {
  background-color: transparent;
  position: absolute;
  pointer-events: none;
}

.xfaWrapped {
  width: 100%;
  height: 100%;
}

:is(.xfaTextfield, .xfaSelect):focus {
  background-image: none;
  background-color: transparent;
  outline: var(--xfa-focus-outline);
  outline-offset: -1px;
}

:is(.xfaCheckbox, .xfaRadio):focus {
  outline: var(--xfa-focus-outline);
}

.xfaTextfield,
.xfaSelect {
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  border: none;
  resize: none;
  background-image: var(--xfa-unfocused-field-background);
}

.xfaSelect {
  padding-inline: 2px;
}

:is(.xfaTop, .xfaBottom) > :is(.xfaTextfield, .xfaSelect) {
  flex: 0 1 auto;
}

.xfaButton {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
}

.xfaLink {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.xfaCheckbox,
.xfaRadio {
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
  border: none;
}

.xfaRich {
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
}

.xfaImage {
  -o-object-position: left top;
  object-position: left top;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.xfaLrTb,
.xfaRlTb,
.xfaTb {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.xfaLr {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.xfaRl {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
}

.xfaTb > div {
  justify-content: left;
}

.xfaPosition {
  position: relative;
}

.xfaArea {
  position: relative;
}

.xfaValignMiddle {
  display: flex;
  align-items: center;
}

.xfaTable {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.xfaTable .xfaRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.xfaTable .xfaRlRow {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  flex: 1;
}

.xfaTable .xfaRlRow > div {
  flex: 1;
}

:is(.xfaNonInteractive, .xfaDisabled, .xfaReadOnly) :is(input, textarea) {
  background: initial;
}

@media print {
  .xfaTextfield,
  .xfaSelect {
    background: transparent;
  }

  .xfaSelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
}

:root {
  --focus-outline: solid 2px blue;
  --hover-outline: dashed 2px blue;
  --freetext-line-height: 1.35;
  --freetext-padding: 2px;
  --editorFreeText-editing-cursor: text;
  --editorInk-editing-cursor: pointer;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (min-resolution: 1.1dppx) {
  :root {
  }
}

@media screen and (forced-colors: active) {
  :root {
    --focus-outline: solid 3px ButtonText;
    --hover-outline: dashed 3px ButtonText;
  }
}

[data-editor-rotation="90"] {
  transform: rotate(90deg);
}
[data-editor-rotation="180"] {
  transform: rotate(180deg);
}
[data-editor-rotation="270"] {
  transform: rotate(270deg);
}

.annotationEditorLayer {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  font-size: calc(100px * var(--scale-factor));
  transform-origin: 0 0;
  cursor: auto;
  z-index: 4;
}

.annotationEditorLayer.freeTextEditing {
  cursor: var(--editorFreeText-editing-cursor);
}

.annotationEditorLayer.inkEditing {
  cursor: var(--editorInk-editing-cursor);
}

.annotationEditorLayer :is(.freeTextEditor, .inkEditor)[draggable="true"] {
  cursor: move;
}

.annotationEditorLayer .selectedEditor {
  outline: var(--focus-outline);
  resize: none;
}

.annotationEditorLayer .freeTextEditor {
  position: absolute;
  background: transparent;
  border-radius: 3px;
  padding: calc(var(--freetext-padding) * var(--scale-factor));
  resize: none;
  width: auto;
  height: auto;
  z-index: 1;
  transform-origin: 0 0;
  touch-action: none;
  cursor: auto;
}

.annotationEditorLayer .freeTextEditor .internal {
  background: transparent;
  border: none;
  top: 0;
  left: 0;
  overflow: visible;
  white-space: nowrap;
  resize: none;
  font: 10px sans-serif;
  line-height: var(--freetext-line-height);
}

.annotationEditorLayer .freeTextEditor .overlay {
  position: absolute;
  display: none;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationEditorLayer .freeTextEditor .overlay.enabled {
  display: block;
}

.annotationEditorLayer .freeTextEditor .internal:empty::before {
  content: attr(default-content);
  color: gray;
}

.annotationEditorLayer .freeTextEditor .internal:focus {
  outline: none;
}

.annotationEditorLayer .inkEditor.disabled {
  resize: none;
}

.annotationEditorLayer .inkEditor.disabled.selectedEditor {
  resize: horizontal;
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor):hover:not(.selectedEditor) {
  outline: var(--hover-outline);
}

.annotationEditorLayer .inkEditor {
  position: absolute;
  background: transparent;
  border-radius: 3px;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform-origin: 0 0;
  cursor: auto;
}

.annotationEditorLayer .inkEditor.editing {
  resize: none;
  cursor: inherit;
}

.annotationEditorLayer .inkEditor .inkEditorCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
}

:root {
  --viewer-container-height: 0;
  --pdfViewer-padding-bottom: 0;
  --page-margin: 1px auto -8px;
  --page-border: 9px solid transparent;
  --spreadHorizontalWrapped-margin-LR: -3.5px;
  --loading-icon-delay: 400ms;
}

@media screen and (forced-colors: active) {
  :root {
    --pdfViewer-padding-bottom: 9px;
    --page-margin: 8px auto -1px;
    --page-border: 1px solid CanvasText;
    --spreadHorizontalWrapped-margin-LR: 3.5px;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg) translateY(-100%);
}
[data-main-rotation="180"] {
  transform: rotate(180deg) translate(-100%, -100%);
}
[data-main-rotation="270"] {
  transform: rotate(270deg) translateX(-100%);
}

#hiddenCopyElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
}

.pdfViewer {
  /* Define this variable here and not in :root to avoid to reflow all the UI
     when scaling (see #15929). */
  --scale-factor: 1;

  padding-bottom: var(--pdfViewer-padding-bottom);
}

.pdfViewer .canvasWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: var(--page-margin);
  position: relative;
  overflow: visible;
  border: var(--page-border);
  background-clip: content-box;
  background-color: rgba(255, 255, 255, 1);
}

.pdfViewer .dummyPage {
  position: relative;
  width: 0;
  height: var(--viewer-container-height);
}

.pdfViewer.removePageBorders .page {
  margin: 0 auto 10px;
  border: none;
}

.pdfViewer.singlePageView {
  display: inline-block;
}

.pdfViewer.singlePageView .page {
  margin: 0;
  border: none;
}

.pdfViewer:is(.scrollHorizontal, .scrollWrapped),
.spread {
  margin-inline: 3.5px;
  text-align: center;
}

.pdfViewer.scrollHorizontal,
.spread {
  white-space: nowrap;
}

.pdfViewer.removePageBorders,
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .spread {
  margin-inline: 0;
}

.spread :is(.page, .dummyPage),
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) :is(.page, .spread) {
  display: inline-block;
  vertical-align: middle;
}

.spread .page,
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .page {
  margin-inline: var(--spreadHorizontalWrapped-margin-LR);
}

.pdfViewer.removePageBorders .spread .page,
.pdfViewer.removePageBorders:is(.scrollHorizontal, .scrollWrapped) .page {
  margin-inline: 5px;
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}

.pdfViewer .page canvas .structTree {
  contain: strict;
}

.pdfViewer .page canvas[hidden] {
  display: none;
}

.pdfViewer .page canvas[zooming] {
  width: 100%;
  height: 100%;
}

.pdfViewer .page.loadingIcon::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  display: none;
  /* Using a delay with background-image doesn't work,
     consequently we use the display. */
  transition-property: display;
  transition-delay: var(--loading-icon-delay);
  z-index: 5;
  contain: strict;
}

.pdfViewer .page.loading::after {
  display: block;
}

.pdfViewer .page:not(.loading)::after {
  transition-property: none;
  display: none;
}

.pdfPresentationMode .pdfViewer {
  padding-bottom: 0;
}

.pdfPresentationMode .spread {
  margin: 0;
}

.pdfPresentationMode .pdfViewer .page {
  margin: 0 auto;
  border: 2px solid transparent;
}
</style>
