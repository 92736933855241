import { getFile, getIFileUri } from "@/helpers/api";
import { base64ToBlob } from "@/helpers/filesReadWrite";
import {
  IFile,
  IFileGroup,
  ITaskFile,
  ITutorialFile,
} from "@/modules/j.createWorksheets/stores/createWksStore";
import { useWorksheetCreationStore } from "@/modules/j.createWorksheets/stores/createWksStore";
import requestService from "@/services/requests";
import { computedAsync, computedWithControl } from "@vueuse/core";
import { WatchStopHandle, computed, ref, watch } from "vue";
import { url } from "@/stores/urls";

const useFileHandler = (
  store: ReturnType<typeof useWorksheetCreationStore>
) => {
  const questionFiles = computed<IFile[]>({
    get() {
      // Safely return files that are not marked for deletion
      return (
        store.currentTask0?.task.task_files
          ?.flatMap(tf => tf.file)
          .filter(file => !file.isToDelete) ?? []
      );
    },
    set(files: IFile[]) {

      if (!store.currentTask) {
        console.error('currentTask is undefined');
        return;
      }

      if (!store.currentTask.task_files) {
        store.currentTask.task_files = [];
      }

      files.forEach(file => {
        store.currentTask!.task_files = store.currentTask!.task_files || [];
        const lastOrder = store.currentTask!.task_files.length > 0
          ? store.currentTask!.task_files[store.currentTask!.task_files.length - 1].order
          : 0;
        store.currentTask!.task_files.push({
          uuid: "",
          order: 1,
          file: { ...file, isToDelete: false }
        });
      });



      console.log("Updated task files: ", store.currentTask.task_files);
    },
  });

  const extraInfoFile = computed<IFile[]>({
    get() {
      return store.worksheet.extra_info && !store.worksheet.extra_info.isToDelete
        ? [store.worksheet.extra_info]
        : [];
    },
    set(files: IFile[]) {
      if (!store.worksheet) {
        console.error('worksheet is undefined');
        return;
      }
      if (files.length > 0) {
        store.worksheet.extra_info = { ...files[0], isToDelete: false };
      } else {
        store.worksheet.extra_info = null;
      }

      console.log("Updated extra info file: ", store.worksheet.extra_info);
    },
  });

  const resolvedExtraInfo = computedAsync(
    async () => await resolvedIFiles(extraInfoFile.value)
  );

  const learningScenarioFile = computed<IFile[]>({
    get() {
      return store.worksheet.learning_scenario && !store.worksheet.learning_scenario.isToDelete
        ? [store.worksheet.learning_scenario]
        : [];
    },
    set(files: IFile[]) {
      if (!store.worksheet) {
        console.error('worksheet is undefined');
        return;
      }
      if (files.length > 0) {
        store.worksheet.learning_scenario = { ...files[0], isToDelete: false };
      } else {
        store.worksheet.learning_scenario = null;
      }

      console.log("Updated learning_scenario file: ", store.worksheet.learning_scenario);
    },
  });

  const resolvedlearningScenario = computedAsync(
    async () => await resolvedIFiles(learningScenarioFile.value)
  );

  const resolvedQuestionFilesSource = computedAsync(
    async () => await resolvedIFiles(questionFiles.value)
  );

  // solutionFiles will be splitted into instructionsFilesSource and mediaSolutionSource
  const solutionFiles = computed<Array<IFileGroup | null>>(() => {
    if (
      store.currentActivity === undefined ||
      store.currentActivity.problem === undefined ||
      store.currentActivity.problem === null ||
      store.currentActivity.problem.teacher_solution.length === 0
    ) {
      return [];
    }
    const file =
      store.currentActivity.problem.teacher_solution[0].solution_files;
    return file ?? [];
  });

  const getInstructionsFiles = computed<IFile[]>(() => {
    if (solutionFiles.value.length === 0 || solutionFiles.value[0] === null || solutionFiles.value[0].file.isToDelete === true)
      return [];
    return [solutionFiles.value[0].file];
  });

  const resolvedInstructionsFilesSource = computedAsync(
    async () => await resolvedIFiles(getInstructionsFiles.value)
  );

  function setInstructionFiles(file: IFile | null) {
    console.log("set instruction files called");
    const problem = store.currentActivity?.problem;

    if (problem) {
      if (problem.teacher_solution.length === 0) {
        problem.teacher_solution.push({
          uuid: "",
          order: 1,
          solution_files: [],
          isToDelete: false
        });
      }

      if (file !== null && file !== undefined) {
        const newFileGroup = {
          uuid: "",
          order: 1,
          file: file,
        };
        problem.teacher_solution[0].solution_files.unshift(newFileGroup);
        if (problem.teacher_solution[0].solution_files.length > 1) {
          const secondFileGroup = problem.teacher_solution[0].solution_files[1];
          if (secondFileGroup && secondFileGroup.file) {
            secondFileGroup.file.isToDelete = true;
            secondFileGroup.file.isToUpdate = false;
          }
        }
        problem.teacher_solution[0].solution_files.forEach((fileGroup, index) => {
          if (fileGroup) {
            fileGroup.order = index + 1;
          }
        });
      }
      adjustSolutionFilesOrder(problem.teacher_solution[0].solution_files);
    }
  }


  const getMediaSolutions = computed<IFile[]>(() => {
    const filteredFiles = solutionFiles.value
      .slice(1)
      .filter((fileGroup): fileGroup is IFileGroup =>
        fileGroup !== null &&
        fileGroup.file !== null &&
        fileGroup.file.isToDelete === false
      )
      .map(fileGroup => fileGroup.file);

    return filteredFiles;
  });


  function setMediaSolutions(newFiles: IFile[]) {
    console.log("Adding new media solution files: ", newFiles);
    const problem = store.currentActivity?.problem;

    if (problem) {
      if (problem.teacher_solution.length === 0) {
        problem.teacher_solution.push({
          uuid: "",
          order: 1,
          solution_files: [null],
          isToDelete: false
        });
      }
      const existingFiles = problem.teacher_solution[0].solution_files.slice(1);
      const newOrderStart = existingFiles.length + 1;
      const newFilesWithOrder = newFiles.map((file, index) => ({
        uuid: "",
        order: newOrderStart + index,
        file: file
      }));
      problem.teacher_solution[0].solution_files = [
        problem.teacher_solution[0].solution_files[0],
        ...existingFiles,
        ...newFilesWithOrder
      ];
      console.log("Updated solution files: ", problem.teacher_solution[0].solution_files);
      adjustSolutionFilesOrder(problem.teacher_solution[0].solution_files);
    }
  }



  const resolvedMediaSolutionSource = computedAsync(
    async () => await resolvedIFiles(getMediaSolutions.value)
  );

  const tutorialFiles = computed<IFile[]>(() => {
    const tutorial = store.currentActivity?.tutorial?.tutorialFiles
      ?.filter(f => !f.file.isToDelete)
      .map(f => f.file);

    return Array.isArray(tutorial) ? tutorial : [];
  });

  const resolvedTutorialFilesSource = computedAsync(
    async () => await resolvedIFiles(tutorialFiles.value)
  );

  function setTutorialFiles(files: IFile[]) {
    const tutorial = store.currentActivity?.tutorial;
    if (!tutorial) {
      console.error("Tutorial not found");
      return;
    }

    tutorial.tutorialFiles = tutorial.tutorialFiles || [];
    console.log("TUTORIAL FILES", tutorial.tutorialFiles)
    files.forEach((file, idx) => {
      const uuid = "";
      const iTutorialFile: ITutorialFile = {
        uuid: uuid,
        order: tutorial.tutorialFiles!.length + 1,
        file: file,
      };
      tutorial.tutorialFiles!.push(iTutorialFile);
    });
  }



  function adjustSolutionFilesOrder(solutionFiles: Array<IFileGroup | null>) {
    let idx = 1;
    solutionFiles.forEach((f) => {
      if (f) {
        f.order = idx;
        idx++;
      }
    });
  }

  const getAnswerFilesLength = computed<number>(() => {
    return solutionFiles.value.length;
  });

  return {
    extraInfoFile,
    resolvedExtraInfo,
    questionFiles,
    getInstructionsFiles,
    getMediaSolutions,
    tutorialFiles,
    setInstructionFiles,
    setMediaSolutions,
    setTutorialFiles,
    getAnswerFilesLength,
    resolvedQuestionFilesSource,
    resolvedInstructionsFilesSource,
    resolvedTutorialFilesSource,
    resolvedMediaSolutionSource,
    resolvedlearningScenario,
    learningScenarioFile,
  };
};




const blobCache: Record<string, string> = {};

function removeBlob (cacheKey: string): void {
  console.log("REMOVEU BLOB", cacheKey)
  if (blobCache[cacheKey]) {
    URL.revokeObjectURL(blobCache[cacheKey]) 
    delete blobCache[cacheKey]
    console.log("REMOVEU", cacheKey)
  }
}

async function resolvedIFileNewVersion(file: IFile, shouldFetchFromServer: boolean = true): Promise<string | undefined> {
  const { filename, filetype, filepath } = file;

  if (!filename || !filetype || !filepath) {
    console.log("filename, filetype ou filepath estão vazias.");
    return undefined;
  }

  const cacheKey = `${filepath}-${filename}`;

  if (blobCache[cacheKey]) {
    return blobCache[cacheKey];
  }

  if (!shouldFetchFromServer) {
    return undefined;
  }

  const data = { filetype, filepath, filename };
  const endpoint = "load-media-file";
  const urlPedido = `${url.baseURL}${endpoint}/`;

  try {
    const response = await fetch(urlPedido, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Network error");
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("Failed to get reader");
    }

    const chunks: Uint8Array[] = [];
    let done = false;

    while (!done) {
      const { done: readerDone, value } = await reader.read();
      done = readerDone;
      if (value) {
        chunks.push(value);
      }
    }

    if (chunks.length > 0) {
      const blob = new Blob(chunks, { type: filetype });
      const blobURL = URL.createObjectURL(blob);

      blobCache[cacheKey] = blobURL;

      console.log("Created and cached Blob URL:", blobURL);
      return blobURL;
    } else {
      throw new Error("No data received");
    }

  } catch (error) {
    console.error("Error fetching file:", error);
    return undefined;
  }
}


async function resolvedIFiles(iFiles: IFile[]): Promise<string[]> {
  const resolvedSources: string[] = [];
  for (const file of iFiles) {
    try {
      const uri = await getIFileUri(file);
      console.log("uri: ", uri);
      resolvedSources.push(uri);
    } catch (e) {
      continue;
    }
  }
  return resolvedSources;
}

export { useFileHandler, resolvedIFileNewVersion, resolvedIFiles, removeBlob };
